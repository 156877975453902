html {
	--section-background-color: linear-gradient(
		to bottom left,
		rgba(17, 16, 16, 0.582),
		rgba(12, 8, 24, 0.904)
	);

	--image-gradient: linear-gradient(
		to bottom left,
		rgba(17, 16, 16, 0.678),
		rgba(12, 10, 22, 0.863)
	);

	--imp-text-color: #c770f0;
}

.purple {
	color: var(--imp-text-color) !important;
}

button:focus {
	box-shadow: none !important;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999999;
	background-color: #0c0513;
	background-image: url(./Assets/pre.svg);
	background-repeat: no-repeat;
	background-position: center;
}

#preloader-none {
	opacity: 0;
}

#no-scroll {
	overflow: hidden;
	height: 100vh;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
	width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #2d1950;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgba(178, 121, 216, 0.959);
	border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: rgba(222, 130, 235, 0.911);
	border-radius: 12px;
}

/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
	background-color: #1b1a2ea9 !important;
	transition: all 0.3s ease-out 0s !important;
	box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
	backdrop-filter: blur(15px) !important;
}

.navbar {
	position: fixed !important;
	transition: all 0.3s ease-out 0s !important;
	padding: 0.3rem 2rem !important;
	font-size: 1.2rem !important;
}

.navbar-toggler {
	position: relative !important;
	background-color: transparent !important;
	border-color: transparent !important;
}

.navbar-toggler span {
	display: block !important;
	background-color: #be50f4 !important;
	height: 4px !important;
	width: 27px !important;
	margin-top: 5px !important;
	margin-bottom: 5px !important;
	transform: rotate(0deg) !important;
	left: 0 !important;
	opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
	outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
	transition: transform 0.35s ease-in-out !important;
	transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
	position: absolute !important;
	left: 12px !important;
	top: 10px !important;
	transform: rotate(135deg) !important;
	opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
	height: 12px !important;
	visibility: hidden !important;
	background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
	position: absolute !important;
	left: 12px !important;
	top: 10px !important;
	transform: rotate(-135deg) !important;
	opacity: 0.9 !important;
}

@media (max-width: 767px) {
	.navbar {
		padding: 1rem 2rem !important;
		font-size: 1.4rem !important;
		background-color: #181a27 !important;
	}
	.navbar-nav .nav-item a::after {
		display: none !important;
	}
}
.navbar-brand {
	color: rgb(250, 250, 250) !important;
}

.logo {
	height: 1.4em !important;
	width: 2.5em !important;
}

.navbar-nav .nav-link {
	color: white !important;
	padding-right: 1rem !important;
	padding-left: 1rem !important;
}

.nav-link {
	padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
	.nav-link {
		padding: 0.7rem 1rem !important;
	}
}

.navbar-nav .nav-item {
	position: relative;
	margin-left: 20px;
}

.navbar-nav .nav-item a {
	font-weight: 400;
	transition: all 0.3s ease-out 0s;
	position: relative;
	z-index: 1;
}

.navbar-nav .nav-item a::after {
	content: '';
	position: relative;
	display: block;
	height: 5px;
	width: 0;
	border-radius: 16px;
	background: #c95bf5;
	bottom: 1px;
	left: 0;
	z-index: -1;
	transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
	width: 100%;
}

/* --------- */
/* Home section */
/* --------- */
.wave {
	animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
	animation-duration: 2.1s; /* Change to speed up or slow down */
	animation-iteration-count: infinite; /* Never stop waving :) */
	transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
	display: inline-block;
}

@keyframes wave-animation {
	0% {
		transform: rotate(0deg);
	}
	10% {
		transform: rotate(14deg);
	} /* The following five values can be played with to make the waving more or less extreme */
	20% {
		transform: rotate(-8deg);
	}
	30% {
		transform: rotate(14deg);
	}
	40% {
		transform: rotate(-4deg);
	}
	50% {
		transform: rotate(10deg);
	}
	60% {
		transform: rotate(0deg);
	} /* Reset for the last half to pause */
	100% {
		transform: rotate(0deg);
	}
}
#tsparticles {
	position: fixed !important;
	background-repeat: no-repeat !important;
	background-size: cover !important;
	width: 100%;
	height: 100%;
}

.home-header {
	padding-top: 80px !important;
}

.home-section {
	position: relative;
	z-index: -1;
	background-image: var(--image-gradient), url(./Assets/home-bg.jpg);
	background-position: top center;
	background-repeat: no-repeat;
	padding-bottom: 30px !important;
	padding-top: 30px !important;
}

.home-content {
	padding: 9rem 0 2rem !important;
	color: whitesmoke;
	text-align: left;
}

.heading {
	font-size: 2.4em !important;
}

.heading-name {
	font-size: 2.5em !important;
}

.main-name {
	color: #cd5ff8;
}

.Typewriter__wrapper {
	font-size: 2.2em !important;
	color: #be6adf !important;
	font-weight: 600 !important;
}
.Typewriter__cursor {
	font-size: 2.4em !important;
	color: #b562d6 !important;
}

@media (max-width: 767px) {
	.Typewriter__wrapper {
		font-size: 1.4em !important;
		font-weight: 500 !important;
		position: absolute !important;
	}
	.Typewriter__cursor {
		display: none !important;
	}
}

.myAvtar {
	justify-content: center !important;
	padding-top: 9em !important;
}

@media (max-width: 767px) {
	.myAvtar {
		padding-top: 2em !important;
		padding-bottom: 2em !important;
	}
}

.home-about-section {
	position: relative;
	padding-bottom: 70px !important;
	padding-top: 70px !important;
}

.home-about-description {
	color: white !important;
	padding-top: 100px !important;
	padding-bottom: 20px !important;
	text-align: center;
}

.home-about-body {
	font-size: 1.2em !important;
	text-align: left;
}

.home-about-social {
	text-align: center !important;
	padding-top: 25px;
	color: white !important;
}

.home-about-social-links {
	justify-content: center !important;
	padding-top: 15px !important;
	display: inline-block !important;
	position: relative !important;
	padding-inline-start: 0 !important;
}

.home-social-icons {
	position: relative !important;
	display: inline-block !important;
	width: 40px !important;
	height: 40px !important;
	text-align: center !important;
	font-size: 1.2em !important;
	line-height: 2em !important;
	background: rgba(255, 255, 255, 0.972) !important;
	border-radius: 50% !important;
	transition: 0.5s !important;
}

.home-social-icons::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background: #68187a;
	transition: 0.5s;
	transform: scale(0.9);
	z-index: -1;
}

.home-social-icons:hover::before {
	transform: scale(1.1);
	box-shadow: 0 0 15px #801f95;
}

.home-social-icons:hover {
	color: #87209e;
	box-shadow: 0 0 5px #87209e;
	text-shadow: 0 0 2px #87209e;
}

.social-icons {
	display: inline-block !important;
	padding-right: 15px;
	padding-left: 15px;
}

.icon-colour {
	color: #700c86 !important;
}

/* --------- */
/* Footer */
/* --------- */
.footer {
	background-color: rgb(10, 4, 22);
	bottom: 0 !important;
	padding-top: 10px !important;
	padding-bottom: 8px !important ;
}
.footer-copywright {
	text-align: center !important;
}

.footer-body {
	z-index: 1;
	text-align: center !important;
}

@media (max-width: 767px) {
	.footer-copywright {
		text-align: center !important;
	}

	.footer-body {
		text-align: center !important;
	}
}

.footer h3 {
	font-size: 1em;
	color: white !important;
	margin-top: 0.5em !important;
	margin-bottom: 0.5em !important;
}
.footer-icons {
	margin-top: 0.5em !important;
	margin-bottom: 0.5em !important;
	padding: 0 !important;
}

.blockquote-footer {
	color: #a588c0 !important;
}
/* --------- */
/* Projects */
/* --------- */
.project-section {
	position: relative !important;
	padding-top: 150px !important;
	padding-bottom: 30px !important;
	background-image: var(--section-background-color) !important;
}

.project-card {
	padding-top: 50px !important;
	padding-bottom: 50px !important;
	padding-left: 25px !important;
	padding-right: 25px !important;
	height: auto !important;
}

.project-card-view {
	box-shadow: 0 4px 5px 3px rgba(119, 53, 136, 0.459) !important;
	color: white !important;
	background-color: transparent !important;
	opacity: 0.9 !important;
	transition: all 0.5s ease 0s !important;
	height: 100% !important;
}
.project-card-view:hover,
.latest-blog:hover {
	transform: scale(1.02) !important;
	overflow: hidden !important;
	box-shadow: 0 4px 4px 5px rgba(129, 72, 144, 0.561) !important;
}

.blog-card {
	padding-top: 50px !important;
	padding-bottom: 50px !important;
	padding-left: 25px !important;
	padding-right: 25px !important;
	height: auto !important;
}

.blog-card-view {
	background-color: transparent !important;
	box-shadow: 0 3px 3px 2px rgba(145, 77, 161, 0.459) !important;
	color: white !important;
	transition: all 0.5s ease 0s !important;
	height: 100% !important;
}

.blog-link {
	color: white !important;
	text-decoration: none !important;
}

.blog-link:hover {
	cursor: pointer !important;
}

.blog-card-view:hover {
	transform: scale(1.02) !important;
	overflow: hidden !important;
	box-shadow: 0 3px 3px 5px rgba(155, 88, 173, 0.65) !important;
}

.blog-title {
	color: var(--imp-text-color);
}

.blog-title:hover {
	color: #ccc;
	text-decoration: none !important;
}

.blog-title::after {
	content: '';
	position: relative;
	display: block;
	height: 5px;
	width: 0;
	border-radius: 16px;
	background: #ccc;
	bottom: 1px;
	left: 0;
	z-index: -1;
	transition: all 0.3s ease-out 0s;
}

.blog-title:hover::after {
	width: 39%;
}

.blog-title:hover {
	color: white;
}

.card-img-top {
	padding: 20px !important;
	opacity: 0.8 !important;
	border-radius: 10px !important;
}

.blog-img {
	padding: 0px !important;
	opacity: 0.8 !important;
	border-radius: 0px !important;
}

.btn-primary {
	color: #fff !important;
	background-color: #623686 !important;
	border-color: #623686 !important;
}

.btn-primary:hover {
	color: #fff !important;
	background-color: #6d20c5d7 !important;
	border-color: #6d20c5d7 !important;
}
.btn:focus {
	outline: none !important;
	box-shadow: none !important;
}
.project-heading {
	color: white !important;
	font-size: 2.3em !important;
	font-weight: 500 !important;
	padding-top: 10px !important;
}

/* --------- */
/* About */
/* --------- */

.about-img-container:hover {
	box-shadow: 0 4px 4px 5px rgba(129, 72, 144, 0.561) !important;
}

.about-section {
	position: relative !important;
	padding-top: 150px !important;
	padding-bottom: 30px !important;
	background-image: var(--section-background-color) !important;
	color: white !important;
}

.tech-icons {
	font-size: 4.5em !important;
	margin: 15px !important;
	padding: 10px !important;
	opacity: 0.93 !important;
	border: 1.7px solid rgba(200, 137, 230, 0.637) !important;
	vertical-align: middle !important;
	text-align: center !important;
	border-radius: 5px !important;
	display: table !important;
	box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137) !important;
	overflow: hidden !important;
	transition: all 0.4s ease 0s !important;
}

@media (max-width: 767px) {
	.tech-icons {
		margin: 10px !important;
	}
}

.tech-icons:hover {
	transform: scale(1.05) !important;
	overflow: hidden !important;
	border: 2.2px solid rgba(197, 115, 230, 0.883) !important;
}
.tech-icon-images {
	padding: 20px !important;
	line-height: 1.6 !important;
}

.quote-card-view {
	border: none !important;
	color: white !important;
	background-color: transparent !important;
}

.about-activity {
	list-style: none !important;
	text-align: left !important;
	padding-left: 1px !important;
}

@media (max-width: 767px) {
	.about-img {
		padding-top: 0 !important;
	}
}
/* --------- */
/* Resume */
/* --------- */

.resume-section {
	position: relative !important;
	padding-top: 110px !important;
	padding-bottom: 30px !important;
	background-image: var(--section-background-color) !important;
	color: white !important;
}

.resume {
	padding-top: 50px;
	padding-bottom: 50px;
	justify-content: center;
}

.resume-left {
	padding-right: 80px !important;
}

.resume-right {
	padding-left: 80px !important;
}

@media (max-width: 767px) {
	.resume-left {
		padding-right: 15px !important;
		padding-left: 15px !important;
	}

	.resume-right {
		padding-right: 15px !important;
		padding-left: 15px !important;
	}
}
.resume .resume-title {
	font-size: 2em;
	font-weight: 700;
	padding-top: 30px;
	padding-bottom: 30px;
}

.resume .resume-item {
	padding: 0 0 10px 25px;
	margin-top: -2px;
	border-left: 2px solid #8a49a8;
	position: relative;
}

.resume .resume-item .resume-title {
	line-height: 18px;
	font-size: 0.9em;
	background: #5234795d;
	padding: 8px 15px;
	display: inline-block;
	font-weight: 600;
	margin-bottom: 10px;
}

.resume .resume-item ul {
	padding-left: 20px;
	text-align: justify;
}

.resume .resume-item ul li {
	padding-bottom: 10px;
	list-style: none;
}

.resume .resume-item:last-child {
	padding-bottom: 0;
}

.resume .resume-item::before {
	content: '';
	position: absolute;
	width: 16px;
	height: 16px;
	border-radius: 50px;
	left: -9px;
	top: 0;
	background: #fff;
	border: 2px solid #8a49a8;
}

.like-item {
	padding-top: 10px !important;
	font-size: 1.1em !important;
	font-family: sans-serif !important;
}

.like-btn {
	background-color: #934cce5e !important;
	border-color: #934cce5e !important;
	padding: 0.25rem 0.98rem !important;
	border-radius: 5px !important;
	line-height: 1.4 !important;
	transition: 0.3s ease !important;
}

.like-btn:hover {
	transform: translateY(-2px) !important;
	background-color: #a24dd386 !important;
	border-color: #a24dd386 !important;
}

.animate-like {
	animation-name: likeAnimation;
	animation-fill-mode: forwards;
	animation-duration: 0.85s;
}
@keyframes likeAnimation {
	0% {
		transform: scale(1.5);
	}
	100% {
		transform: scale(1);
	}
}

.fork-btn {
	font-size: 1.1em !important;
	padding-top: 10px !important;
}

.fork-btn-inner {
	line-height: 1.4em !important;
	background-color: #934cce5e !important;
	padding: 0.25rem 1.1rem !important;
	vertical-align: middle !important;
	text-align: center !important;
}

.fork-btn-inner:hover {
	transform: translateY(-2px) !important;
	background-color: #a24dd386 !important;
	border-color: #a24dd386 !important;
}
.fork-btn-inner::after {
	display: none !important;
}
