.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

.latest-blog {
	margin-top: 20px;
	padding: 20px;
	border-radius: 10px;
	background: rgba(255, 255, 255, 0.05);
}
.blog-title {
	display: block;
	margin: 10px 0;
	font-size: 1.2em;
	text-decoration: none;
}
.blog-title:hover {
	text-decoration: underline;
}
.blog-brief {
	color: #ccc;
	font-size: 0.9em;
}

.blog-icon {
	margin-right: 10px;
	animation: glow 2s ease-in-out infinite;
}

@keyframes glow {
	0%,
	100% {
		color: white;
		text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
	}
	50% {
		color: #623686;
		text-shadow: none;
	}
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
